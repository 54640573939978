@mixin fancy-scroll {
    &::-webkit-scrollbar {
        height: 9px;
        width: 9px;
    }

    &::-webkit-scrollbar-button:start:decrement,
    &::-webkit-scrollbar-button:end:increment {
        background: transparent;
        display: none;
    }

    &::-webkit-scrollbar-track-piece {
        background: #f3f3f3;
    }

    &::-webkit-scrollbar-track-piece:vertical:start {
        border-radius: 5px 5px 0 0;
    }

    &::-webkit-scrollbar-track-piece:vertical:end {
        border-radius: 0 0 5px 5px;
    }

    &::-webkit-scrollbar-track-piece:horizontal:start {
        border-radius: 5px 0 0 5px;
    }

    &::-webkit-scrollbar-track-piece:horizontal:end {
        border-radius: 0 5px 5px 0;
    }

    &::-webkit-scrollbar-thumb {
        background: #cbced0;
        border-radius: 5px;
        display: block;
        height: 50px;
    }
}

.fancy-scroll {
    @include fancy-scroll;
}

.fancy-scroll-dark {
    @include fancy-scroll;

    &::-webkit-scrollbar-track-piece {
        background: #2e3c43;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.24);
    }
}
