@import './css/colors.scss';
@import './css/spacings.scss';
@import './css/typography.scss';
@import './css/nav.scss';

$badge-border-radius: 100px;
$border-radius: 0.8rem;
$border-radius-lg: 3.2rem;

$grid-gutter-width: 4rem;

$form-select-border-radius: 10rem;

$popover-arrow-width: 0rem;
$popover-arrow-height: 0rem;

$enable-rfs: false !default;

@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/variables.scss';
@import './css/modals.scss';
@import './css/buttons.scss';
@import './css/animations.scss';
@import './css/borders.scss';
@import './css/popovers.scss';
@import './css/popups.scss';
@import './css/util.scss';

:root {
    font-size: 62.5%;
}

#root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    justify-content: center;
    background-color: #fff;
}

body {
    background: #e5e5e5;
    line-height: 2.4rem;
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

code {
    font-family: 'Source Sans Pro', sans-serif;
}

li::marker {
    color: $black-400;
}

.fs-icon {
    font-size: 1.8rem !important;
    &--large {
        font-size: 2.4rem !important;
    }
    &--small {
        font-size: 1rem !important;
    }
}

.dropdown-item {
    &--tractiq:hover {
        background-color: $backgroundSmoke !important;
        color: $black !important;
        text-shadow: none !important;
    }
    &--standard:hover {
        background-color: $black-100;
    }
    &--tractiq-active {
        background-color: #1257dd1f !important;
        // simulate bold font to not change width of dropdown menu
        text-shadow: 0 0 0.6px $tractiq, 0 0 0.6px $tractiq !important;
        color: $tractiq !important;
    }
}

.dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    &--small {
        height: 3px;
        width: 3px;
    }
}

.empty-chart {
    border-radius: 4px !important;
    .highcharts-background {
        fill: #f5f5f5;
    }
}

.carousel-scroller {
    font-size: 2.8rem !important;
}

.value-square {
    width: 164px;
    height: 164px;
    border-radius: 24px;
    font-size: 2.4rem;
}

.minw-0 {
    min-width: 0;
}

.minh-100 {
    height: 100%;
}

.link--tractiq:hover {
    color: $tractiq;
}

.google-pin-img {
    width: 18px;
    height: 18px;
}

.download-options {
    width: 260px;
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: white;
    border-radius: 8px;
    z-index: 10;
    &__selected {
        background-color: $black-100;
    }
    &__retail_gap_report {
        right: 0;
        top: 45px;
        margin-right: 0 !important;
    }
}

.dropdown-menu-min-width {
    min-width: 250px;
}

.dropdown-menu-fit-content {
    width: fit-content !important;
}

.export-map-container {
    position: fixed !important;
    top: 0;
    left: -2501px;
    height: 2501px;
    width: 2501px;
}

.score-benchmark {
    height: 20px;
    padding: 0;
}

.h-60x {
    height: 60px;
}

.h-20x {
    height: 20px;
}

.w-20x {
    width: 20px;
}

.h-100x {
    height: 100px;
}

.left-0 {
    left: 0;
}

.highcharts-menu-item {
    font-size: 16px !important;
}

@include media-breakpoint-up(lg) {
    .shadow-effect {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }
}

@include media-breakpoint-down(lg) {
    .shadow-effect-small {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }
}

.arrow-green {
    color: #4c9689;
}

.arrow-red {
    color: #eb4d5c;
}

.truncated-text-container {
    max-height: 3em; 
    overflow: hidden;
    position: relative;
  }
  
  .truncated-text {
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  

.backdrop-filter-5 {
    backdrop-filter: blur(5px);
    z-index: 100;
}

.beta-tag,
.beta-tag-dropdown-list {
    color: black;
}

.beta-tag {
    transform: translate(-22px, -6px);
}

.beta-tag-dropdown-list {
    transform: translate(-36px, -5px);
}

.info-card {
    background-color: $black-100;
}

.info-card-indicator {
    width: 4px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: $tractiq-800;
}
